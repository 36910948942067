import "../../CustomStyles/customStyle.css";
import Minagaramlogo from "../../assets/Agarathi_logo.png";
import PlayStorelogo from "../../assets/SharethisApp/PlayStore.svg";
import AppStorelogo from "../../assets/SharethisApp/AppStore.svg";
import STA_MobileScreen from "../../assets/SharethisApp/SharethisApp_MobileScreen.png";
import { Button, Menu, Dropdown, message } from "antd";
import BackgroundImage from "../../assets/background_Image.png";
import { ShareAltOutlined, LinkOutlined } from "@ant-design/icons"; // Importing share icon
import { WhatsappShareButton, WhatsappIcon } from "react-share";
import {
  STR_Agarathi,
  STR_Copy_Failure_STA,
  STR_Copy_STA,
  STR_Copy_Success_STA,
  STR_Share,
  STR_Tam_agarathi,
  STR_Tam_Copy_Failure_STA,
  STR_Tam_Copy_STA,
  STR_Tam_Copy_Success_STA,
  STR_Tam_Share,
  STR_Tam_text1_STA,
  STR_Tam_text2_STA,
  STR_Tam_text3_STA,
  STR_text1_STA,
  STR_text2_STA,
  STR_text3_STA,
} from "../../Config.js/Strings";
import { connect } from "react-redux";

const SharethisApp = ({ isTamil }) => {
  let agarathi_token = localStorage.getItem("agarathi_token");

  const shareUrl = "http://13.126.65.58/SharethisApp_1";

  const handleCopy = async () => {
    try {
      if (navigator.clipboard && window.isSecureContext) {
        await navigator.clipboard.writeText(shareUrl);
        message.success(
          isTamil ? STR_Tam_Copy_Success_STA : STR_Copy_Success_STA
        );
      } else {
        // Fallback for non-secure contexts or browsers without clipboard API
        const textArea = document.createElement("textarea");
        textArea.value = shareUrl;
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        try {
          document.execCommand("copy");
          message.success(
            isTamil ? STR_Tam_Copy_Success_STA : STR_Copy_Success_STA
          );
        } catch (err) {
          message.error(
            isTamil ? STR_Tam_Copy_Failure_STA : STR_Copy_Failure_STA
          );
        } finally {
          document.body.removeChild(textArea);
        }
      }
    } catch (error) {
      message.error(isTamil ? STR_Tam_Copy_Failure_STA : STR_Copy_Failure_STA);
    }
  };

  const menu = (
    <Menu>
      <Menu.Item key="1">
        <WhatsappShareButton url={shareUrl}>
          <WhatsappIcon size={32} round />
        </WhatsappShareButton>
      </Menu.Item>
      {/* <Menu.Item key="2">
        <FacebookShareButton url={shareUrl}>
          <FacebookIcon size={32} round />
        </FacebookShareButton>
      </Menu.Item>
      <Menu.Item key="3">
        <TwitterShareButton url={shareUrl}>
          <TwitterIcon size={32} round />
        </TwitterShareButton>
      </Menu.Item> */}
      <Menu.Item key="2" onClick={handleCopy}>
        <Button icon={<LinkOutlined />} type="link">
          {isTamil ? STR_Tam_Copy_STA : STR_Copy_STA}
        </Button>
      </Menu.Item>
    </Menu>
  );

  return (
    <div
      className={`${!agarathi_token && "pp"}`}
      style={{
        background: `url(${BackgroundImage})`,
        backgroundSize: "auto",
        backgroundPosition: "top",
        backgroundRepeat: "no-repeat",
      }}
    >
      {/* {!agarathi_token && (
        <Header className="header_STA_1">
          <div className="flex gap-4 items-center">
            <Link to="/">
              <img
                src={
                  "https://tamileagarathi.s3.ap-south-1.amazonaws.com/siteimages/Agarathi.png"
                }
                alt="Logo"
                className="logo-size-img"
              ></img>
            </Link>
            <div
              className="font-bold text-base hidden lg:block"
              style={{ color: "black" }}
            >
              {STR_Agarathi}
            </div>
          </div>

          <ConfigProvider
            theme={{
              token: {
                // Seed Token
                colorPrimary: "#5cd0ff",
                borderRadius: 20,
              },
            }}
          >
            {" "}
            <Link to="/">
              <Button className="initialLoginButton">
                {isTamil ? STR_Tam_Login : STR_Login}
              </Button>
            </Link>
          </ConfigProvider>
        </Header>
      )} */}
      <div className="wholecontainer_STA">
        <div className="container_STA">
          <div className="minagaramlogoContainer_STA">
            <img
              src={Minagaramlogo}
              alt="minagaram logo"
              className="minagaramlogo_STA"
            />
          </div>
          <h1 className="header_STA">
            {isTamil ? STR_Tam_agarathi : STR_Agarathi}{" "}
          </h1>
          <Dropdown
            overlay={menu}
            trigger={["click"]}
            className="shareIcon_STA"
          >
            <Button type="black" icon={<ShareAltOutlined />}>
              {isTamil ? STR_Tam_Share : STR_Share}
            </Button>
          </Dropdown>
          <p className="text_STA_1">
            {isTamil ? STR_Tam_text1_STA : STR_text1_STA}
          </p>
          <p className="text_STA">
            {isTamil ? STR_Tam_text2_STA : STR_text2_STA}
          </p>
          <p className="textBold_STA">
            {isTamil ? STR_Tam_text3_STA : STR_text3_STA}
          </p>
          <div className="shareButtonContainer_STA">
            <div className="shareButton_STA">
              <a
                href="https://apps.apple.com/sg/app/min-agaram/id6483002626"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={AppStorelogo}
                  alt="App Store"
                  className="AppStorelogo"
                />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.tamil_agarathi_updated"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={PlayStorelogo}
                  alt="Google Play"
                  className="PlayStorelogo"
                />
              </a>
            </div>
          </div>
        </div>
        <img
          src={STA_MobileScreen}
          alt="MobileScreen"
          className="mobileScreen_STA"
        />
      </div>
    </div>
  );
};

// Map Redux state to component props
const mapStateToProps = (state) => ({
  isTamil: state.LanguageReducer.isTamil,
});

export default connect(mapStateToProps)(SharethisApp);
